import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/return`}
          component={lazy(() => import(`./return`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/organization`}
          component={lazy(() => import(`./organization`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/shop`}
          component={lazy(() => import(`./shop`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/refurbished`}
          component={lazy(() => import(`./refurbished`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/repair`}
          component={lazy(() => import(`./repair`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/repair`}
          component={lazy(() => import(`./repair`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sell`}
          component={lazy(() => import(`./sell`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/variations`}
          component={lazy(() => import(`./variations`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/images`}
          component={lazy(() => import(`./images`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/shop/products`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
