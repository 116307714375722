import {
  DashboardOutlined,
  TeamOutlined,
  SettingOutlined,
  ReadOutlined,
  EnvironmentOutlined,
  DatabaseOutlined,
  DiffOutlined,
  ToolOutlined,
  FormOutlined,
  BookOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const returnNavTree = [
  {
    key: "return",
    path: `${APP_PREFIX_PATH}/return`,
    title: "sidenav.return",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const organizationNavTree = [
  {
    key: "organization",
    path: `${APP_PREFIX_PATH}/organization`,
    title: "sidenav.organization",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "organization-customers",
        path: `${APP_PREFIX_PATH}/organization/customers`,
        title: "sidenav.organization.customers",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "organization-stores",
        path: `${APP_PREFIX_PATH}/organization/stores`,
        title: "sidenav.organization.stores",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const shopNavTree = [
  {
    key: "shop",
    path: `${APP_PREFIX_PATH}/shop`,
    title: "sidenav.shop",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "shop-orders",
        path: `${APP_PREFIX_PATH}/shop/orders`,
        title: "sidenav.shop.orders",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-products",
        path: `${APP_PREFIX_PATH}/shop/products`,
        title: "sidenav.shop.products",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-categories",
        path: `${APP_PREFIX_PATH}/shop/categories`,
        title: "sidenav.shop.categories",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-brands",
        path: `${APP_PREFIX_PATH}/shop/brands`,
        title: "sidenav.shop.brands",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "shop-models",
        path: `${APP_PREFIX_PATH}/shop/models`,
        title: "sidenav.shop.models",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "shop-colour-types",
        path: `${APP_PREFIX_PATH}/variations/colours`,
        title: "sidenav.shop.colour-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "shop-product-types",
        path: `${APP_PREFIX_PATH}/shop/product-types`,
        title: "sidenav.shop.product-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const refurbishedNavTree = [
  {
    key: "refurbished",
    path: `${APP_PREFIX_PATH}/refurbished`,
    title: "sidenav.refurbished",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "refurbished-orders",
        path: `${APP_PREFIX_PATH}/refurbished/orders`,
        title: "sidenav.refurbished.orders",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "refurbished-products",
        path: `${APP_PREFIX_PATH}/refurbished/products`,
        title: "sidenav.refurbished.products",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "refurbished-brands",
        path: `${APP_PREFIX_PATH}/refurbished/brands`,
        title: "sidenav.refurbished.brands",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "refurbished-colour-types",
        path: `${APP_PREFIX_PATH}/variations/colours`,
        title: "sidenav.refurbished.colour-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "refurbished-memory-types",
        path: `${APP_PREFIX_PATH}/variations/memories`,
        title: "sidenav.refurbished.memory-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "refurbished-condition-types",
        path: `${APP_PREFIX_PATH}/variations/conditions`,
        title: "sidenav.refurbished.condition-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const repairNavTree = [
  {
    key: "repair",
    path: `${APP_PREFIX_PATH}/repair`,
    title: "sidenav.repair",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "repair-bookings",
        path: `${APP_PREFIX_PATH}/repair/bookings`,
        title: "sidenav.repair.bookings",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "repair-models",
        path: `${APP_PREFIX_PATH}/repair/models`,
        title: "sidenav.repair.models",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "repair-devices",
        path: `${APP_PREFIX_PATH}/repair/devices`,
        title: "sidenav.repair.devices",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "repair-brands",
        path: `${APP_PREFIX_PATH}/repair/brands`,
        title: "sidenav.repair.brands",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "repair-colour-types",
        path: `${APP_PREFIX_PATH}/repair/types`,
        title: "sidenav.repair.colour-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const sellMobileNavTree = [
  {
    key: "sell",
    path: `${APP_PREFIX_PATH}/sell`,
    title: "sidenav.sell",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "sell-models",
        path: `${APP_PREFIX_PATH}/sell/models`,
        title: "sidenav.sell.models",
        icon: DiffOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sell-brands",
        path: `${APP_PREFIX_PATH}/sell/brands`,
        title: "sidenav.sell.brands",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sell-memory-types",
        path: `${APP_PREFIX_PATH}/variations/memories`,
        title: "sidenav.sell.memory-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sell-condition-types",
        path: `${APP_PREFIX_PATH}/variations/conditions`,
        title: "sidenav.sell.condition-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sell-colour-types",
        path: `${APP_PREFIX_PATH}/variations/colours`,
        title: "sidenav.sell.colour-types",
        icon: DiffOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const imagesNavTree = [
  {
    key: "images",
    path: `${APP_PREFIX_PATH}/images`,
    title: "sidenav.images",
    icon: DiffOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [
  ...returnNavTree,
  ...organizationNavTree,
  ...shopNavTree,
  ...refurbishedNavTree,
  ...repairNavTree,
  ...sellMobileNavTree,
  ...imagesNavTree,
];

export default navigationConfig;
